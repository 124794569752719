.switch-container-on {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  padding-left:  3px;
  padding-right: 3px;
  height: 22px;
  width: 35px;
  border-radius: 20px;
  background-color: #59AFFF;
}

.switch-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding-left:  3px;
  padding-right: 3px;
  height: 22px;
  width: 35px;
  border-radius: 20px;
  background-color: #e7e7e7;
}

.swith-dot {
  background-color: white;
  height: 18px;
  width: 18px;
  border-radius: 9px;
}

.switch-container:hover {
  cursor: pointer;
}

.switch-container-on {
  cursor: pointer;
}
