.new-task-container {
  display: flex;
  flex-direction: column;
}

.new-task-title {
  margin-bottom: 10px;
}

.new-task-button {
  margin-top: 5px;
  padding: 15px;
  font-size: 13px;
}

.new-task-input {
  flex: 1;
}

.new-task-input-row {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 10px;
}

.new-task-input-key {
  font-size: 13px;
  width: 80px;
  color: #696969;
}

.new-task-input-wide-key {
  font-size: 13px;
  width: 140px;
  color: #696969;
}

.new-task-hours-button {
  font-size: 20px;
  color: #59AFFF;
}

.new-task-hours-button:hover {
  cursor: pointer;
  color: #69b7ff;
}

.new-task-hours-number {
  color: #696969;
  width: 40px;
  text-align: center;
  font-size: 14px;
}

.new-task-hours-container {
  height: 30px;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  flex: 1;
  align-items: center;
}
