.add-name-container {
  display: flex;
  flex-direction: column;
  width: 300px;
  background-color: #F4F4F4;
  border-radius: 12px;
  padding: 10px;
  padding-top: 5px;
  padding-left: 15px;
  padding-bottom: 12px;
  padding-right: 18px;
}

.add-name-title {
  color: #3D3D3D;
  font-size: 15px;
  font-weight: bold;
}

.add-name-subtitle {
  font-size: 14px;
  color: #696969;
}

.add-name-button-container {

}

.add-name-input {
  width: 282px;
  margin-bottom: 15px;
}

.add-name-button {
  align-self: flex-end;
  padding-right: 15px;
  padding-left: 15px;
  font-weight: bold;
  width: 100px;
}
