.task-details-container {
  display: flex;
  flex-direction: column;
}

.task-details-title-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.task-details-title {

}

.task-details-edit-button {
  font-size: 14px;
  font-weight: 500;
  padding: 5px;
  padding-left: 30px;
  color: #59AFFF;
}

.task-details-edit-button:hover {
  cursor: pointer;
  color: #69b7ff;
}

.task-details-subtitle {
  margin-bottom: 0px;
  font-size: 13px;
  color: #696969;
}

.task-details-button {
  margin-top: 5px;
  padding: 15px;
  font-size: 13px;
}

.task-details-input {
  flex: 1;
}

.task-details-input-row {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 10px;
}

.task-details-input-key {
  font-size: 13px;
  width: 80px;
  color: #696969;
}

.task-details-input-wide-key {
  font-size: 13px;
  width: 140px;
  color: #696969;
}

.task-details-status-key {
  font-size: 13px;
  width: 120px;
  color: #696969;
}

.task-details-hours-button {
  font-size: 20px;
  color: #59AFFF;
}

.task-details-hours-button:hover {
  cursor: pointer;
  color: #69b7ff;
}

.task-details-progress-number {
  color: #696969;
  font-size: 13px;
  margin-left: 15px;
  margin-right: 15px;
  width: 40px;
  text-align: center;
}

.task-details-hours-number {
  color: #696969;
  font-size: 13px;
  margin-left: 15px;
  margin-right: 15px;
  width: 40px;
  text-align: center;
}

.task-details-hours-container {
  height: 30px;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  flex: 1;
  align-items: center;
}

.task-edit-section-buffer {
  height: 15px;
}

.task-details-track-hours-switch-container {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  flex: 1;
  margin-top: 10px;
  margin-bottom: 5px;
}

.comments-section {

}

.comment-container {
  margin-top: 10px;
  margin-bottom: 10px;
}

.comment-sender-container {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-between;
}

.comment-sender-name {
  font-size: 12.4px;
  font-weight: 500;
  margin-bottom: 5px;
  color: #696969;
}

.comment-delete-text {
  font-size: 12px;
  margin-bottom: 5px;
  color: #696969;
}

.comment-delete-text:hover {
  cursor: pointer;
  color: #ff6666;
}

.comment-text {
  font-size: 13px;
  color: #696969;
}

.comment-textarea-container {
  display: flex;
  flex-direction: row;
}

.comment-input-textarea {
  flex: 1;
  font-family: 'Rubik';
  border-radius: 8px;
  border-width: 0px;
  background-color: #ffffff;
  color: #696969;
  padding: 10px;
  font-size: 13px;
  border: 2px solid #ffffff;
  margin-top: 10px;
}

.comment-input-textarea:focus {
  outline: none;
  border: 2px solid #59AFFF;
}

.comment-add-button {
  margin-top: 10px;
  padding: 10px;
  padding-right: 20px;
  padding-left: 20px;
  align-self: flex-end;
  width: 130px;
}

.comment-add-button-container {
  display: flex;
  flex-direction: column;
}
