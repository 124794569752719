.main-container-sections {
  display: flex;
  flex-direction: row;
}

.phases-screen-container {
  flex: 1;
  padding-right: 30px;
  padding-left: 10px;
  margin-top: 20px;
  font-family: 'Rubik';
  display: flex;
  flex-direction: row;
}

.phases-screen-loading-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 90px;
}

.phases-screen-loading-text {
  margin-top: 15px;
  font-size: 14px;
}
