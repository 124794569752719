.card {
  display: flex;
  flex-direction: column;
  background-color: #f4f4f4;
  border-radius: 10px;
  border-bottom-right-radius: 0px;
  flex: 1;
  overflow: hidden;
}

.card-completed-phase {
  display: flex;
  flex-direction: column;
  background-color: #f4f4f4;
  border-radius: 10px;
  flex: 1;
  overflow: hidden;
}

.phase-cards-container {
  display: flex;
  margin-right: 25px;
  flex-direction: column;
}

.phase-seperator-line {
  margin-top: 0;
  margin-bottom: 0;
}

.phase-card-header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 8px;
  padding-left: 15px;
  padding-right: 15px;
}

.title {
  font-size: 15px;
  font-weight: 500;
  color: #3d3d3d;
}

.details-container {
  background-color: white;
  padding: 8px;
  padding-right: 15px;
  padding-left: 15px;
  border-radius: 8px;
  color: #3d3d3d;
}

.details-container:hover {
  cursor: pointer;
  color: #696969;
}

.send-to-completed-container {
  background-color: #59AFFF;
  padding: 8px;
  width: 130px;
  border-radius: 8px;
  color: #3d3d3d;
  margin-right: 8px;
  text-align: center;
  display: flex;
  flex-direction: column;
}

.send-to-completed-container:hover {
  cursor: pointer;
  color: #69b7ff;
}

.add-first-task-container {
  height: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.add-first-task-button {
  display: flex;
  flex-direction: row;
  background-color: #59AFFF;
  padding: 8px;
  width: 100px;
  border-radius: 8px;
  color: #3d3d3d;
  text-align: center;
  align-items: center;
  justify-content: center;
  color: white;
}

.add-first-task-button:hover {
  cursor: pointer;
  background-color: #69b7ff;
}

.add-first-task-text {
  font-size: 12px;
  font-weight: 500;
  margin-left: 5px;
  color: white;
}

.add-first-task-icon {
  font-size: 12px;
}

.send-to-completed {
  font-size: 12px;
  font-weight: 500;
  color: white;
}

.details {
  font-size: 12px;
  font-weight: 500;
}

.table-header {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding-top: 8px;
  padding-bottom: 8px;
  padding-left: 15px;
}

.task-name {
  font-size: 11px;
  font-weight: 500;
  color: #696969;
  flex: 1;
}

.assignee {
  font-size: 11px;
  font-weight: 500;
  color: #696969;
  width: 160px;
}

.type {
  font-size: 11px;
  font-weight: 500;
  color: #696969;
  width: 120px;
}

.status {
  font-size: 11px;
  font-weight: 500;
  color: #696969;
  width: 100px;
}

.progress {
  font-size: 11px;
  font-weight: 500;
  color: #696969;
  width: 98px;
}

.task-progress {
  display: flex;
  flex-direction: column;
}

.task-progress-row {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding-top: 12px;
  padding-bottom: 12px;
  padding-left: 15px;
}

.task-progress-row:hover {
  cursor: pointer;
  background-color: #fafafa;
}

.task-progress-row div {
  font-size: 13px;
  color: #696969;
}

.task-progress-name {
  flex: 1;
}

.task-progress-assignee {
  width: 160px;
}

.task-progress-type {
  width: 120px;
}

.task-progress-status {
  width: 100px;
}

.task-progress-progress {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 98px;
}

.task-progress-progress-hrs {
  margin-left: 15px;
}

.phase-row-container {
  display: flex;
  flex-direction: column;
}

.add-task-container {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  color: #59AFFF;
}

.add-task-container:hover {
  cursor: pointer;
  color: #69b7ff;
}

.curve-container {
  background-color: #f4f4f4;
  width: 30px;
}

.cuver-white-container {
  height: 100%;
  width: 100%;
  background-color: white;
  border-top-right-radius: 10px;
}

.add-task-button {
  background-color: #f4f4f4;
  padding: 8px 15px;
  border-bottom-right-radius: 10px;
  border-bottom-left-radius: 10px;
}

.phase-button-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  background-color: #f4f4f4;
  border-radius: 12px;
  padding: 20px;
  padding-top: 12px;
  padding-bottom: 12px;
}

.phase-button-container:hover {
  cursor: pointer;
  background-color: #f6f6f6;
}

.phase-button-icon {
  color: #59AFFF;
}

.phase-button-label {
  font-size: 13px;
  font-weight: 500;
  color: #696969;
  margin-left: 8px;
}

.phase-button-separator {
  width: 5px;
}

.phase-buttons-container {
  display: flex;
  flex-direction: row;
  margin-top: 15px;
  justify-content: flex-end;
  margin-bottom: 40px;
}

.empty-completed-phase-text {
  text-align: center;
  margin-top: 40px;
  margin-bottom: 40px;
  font-size: 14px;
  color: grey;
}
