.navigation-container {
  width: 230px;
  padding: 10px;
  padding-top: 0px;
}

.navigation-button-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 5px;
  padding: 12px;
  padding-left: 15px;
  border-radius: 6px;
}

.navigation-button-container:hover {
  cursor: pointer;
  background-color: #EEF7FF;
}

.navigation-button-title {
  font-size: 15px;
  color: #696969;
}

.navigation-button-icon {
  margin-right: 15px;
  width: 20px;
  color: #696969;
}

.navigation-phases-selected-icon {
  color: #59AFFF;
}

.navigation-team-selected-icon {
  color: #59AFFF;
}

.navigation-settings-selected-icon {
  color: #59AFFF;
}

.selected-title {
  color: #59AFFF;
}
