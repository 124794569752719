.received-invitations-container {
  display: flex;
  flex-direction: column;
  background-color: #F4F4F4;
  width: 290px;
  padding: 16px;
  padding-right: 18px;
  padding-left: 18px;
  padding-bottom: 18px;
  border-radius: 10px;
  margin-top: 10px;
  margin-right: 10px;
}

.container-title {
  color: #3D3D3D;
  font-size: 15px;
  font-weight: bold;
}

.invite-separator-lines {
  margin-top: 16px;
  margin-bottom: 16px;
}

.invite-row-container {

}

.invite-row {

}

.invite-buttons-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
}

.invite-accept-button {
  margin-left: 5px;
  width: 70px;
}

.invite-decline-button {
  width: 70px;
}

.invite-project-title {
  color: #3D3D3D;
  font-size: 15px;
  font-weight: 500;
}

.invite-details-container {
  display: flex;
  flex-direction: column;
  margin-top: 5px;
  margin-bottom: 10px;
}

.invite-details-text {
  font-size: 14px;
  color: #696969;
}
