.default-input {
  font-family: 'Rubik';
  height: 30px;
  border-radius: 8px;
  border-width: 0px;
  background-color: #ffffff;
  color: #4c4c4c;
  padding: 3px;
  padding-left: 10px;
  font-size: 13px;
  border: 2px solid #ffffff;
}

.default-input:focus {
  outline: none;
  border: 2px solid #59AFFF;
}
