.completed-pane-container {
  display: flex;
  flex-direction: column;
  width: 290px;
  padding: 16px;
  padding-top: 0px;
  padding-right: 18px;
  padding-left: 18px;
  padding-bottom: 18px;
  border-radius: 10px;
}

.completed-phase-row {
  background-color: #f4f4f4;
  color: #696969;
  padding: 15px;
  border-radius: 12px;
  margin-bottom: 5px
}

.completed-phase-row:hover {
  cursor: pointer;
  background-color: #f8f8f8;
}
