.progress-bar {
  background-color: #E4E4E4;
  height: 5px;
  border-radius: 10px;
  overflow: hidden;
}

.progress-indicator {
  background-color: #59AFFF;
  height: 5px;
}

.progress-indicator-completed {
  background-color: #64E300;
  height: 5px;
}
