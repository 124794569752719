.button-default {
  background: #59AFFF;
  border-radius: 8px;
  color: white;
  font-weight: 500;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 13px;
  padding: 8px;
  padding-bottom: 10px;
}

.button-default:hover {
  background: #69b7ff;
  cursor: pointer;
}

.button-grey {
  background: #dbdbdb;
  border-radius: 8px;
  color: #696969;
  font-weight: 500;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 13px;
  padding: 8px;
  padding-bottom: 10px;
}

.button-grey:hover {
  background: #e2e2e2;
  cursor: pointer;
}
