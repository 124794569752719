.fullscreen {
  display: flex;
  font-family: 'Rubik';
  justify-content: center;
  align-items: center;
  height: 100vh;
  background-image: url('../../assets/landing-background.jpg');
  background-repeat: repeat;
}

.center-container {
  width: 250px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: white;
  border-radius: 20px;
  padding-bottom: 20px;
  padding-top: 10px;
  box-shadow: 0px 0px 42px 22px rgba(0,114,188, 0.2);
}

.sign-in-google-button {
  display: flex;
  flex-direction: row;
  align-items: center;
  background: #F4F4F4;
  border-radius: 8px;
  padding: 12px;
  padding-right: 25px;
  padding-left: 25px;
  margin-top: 8px;
}

.sign-in-google-button:hover {
  cursor: pointer;
  background: #f6f6f6;
}

.sign-in-google-button-title {
  font-size: 14px;
  color: #626262;
  font-weight: 600;
  margin-left: 5px;
}

.main-title {
  font-size: 30px;
  font-weight: bold;
  color: #404040;
  margin-top: -10px;
}
