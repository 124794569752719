.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.12);
  display: flex;
  justify-content: center;
  align-items: center;
}

.modal {
  font-family: 'Rubik';
  position: relative;
  border-radius: 10px;
  max-width: 500px;
  max-height: 90%;
  overflow-y: auto;
  background-color: #f4f4f4;
}

.modal-header {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  padding: 15px;
  padding-bottom: 10px;
}

.modal-header-title {
  color: #3D3D3D;
  font-size: 15px;
  font-weight: 500;
  margin-bottom: 3px;
}

.modal-header-subtitle {
  font-size: 14px;
  color: #696969;
}

.modal-content {

}

.modal-footer {
  display: flex;
  justify-content: flex-end;
}

.modal-close-button {
  font-size: 20px;
  color: #59AFFF;
}

.modal-close-button:hover {
  color: #69b7ff;
  cursor: pointer;
}
