.add-project-container {
  display: flex;
  flex-direction: column;
  width: 300px;
  background-color: #F4F4F4;
  border-radius: 12px;
  padding: 15px;
  padding-top: 0px;
}

.add-project-title {
  color: #3D3D3D;
  font-size: 15px;
  font-weight: bold;
  margin-bottom: 3px;
}

.add-project-subtitle {
  font-size: 14px;
  color: #696969;
}

.add-project-button-container {

}

.add-project-input {
  width: 282px;
  margin-bottom: 10px;
}

.add-project-button {
  align-self: flex-end;
  padding-top: 10px;
  padding-right: 25px;
  padding-left: 25px;
}
