.user-profile-name-input {
  width: 282px;
  margin-bottom: 18px;
  margin-top: 8px;
}

.user-profile-name-label {
  font-size: 15px;
  color: #696969;
  margin-top: 5px;
}

.user-profile-signed-in-title {
  color: #3D3D3D;
  font-size: 15px;
  font-weight: 500;
  margin-bottom: 3px;
}

.user-profile-signed-in-email {
  font-size: 14px;
  color: #696969;
}

.user-profile-save-button {
  padding: 10px 30px;
  align-self: flex-end;
}

.user-profile-sign-out-button {
  padding: 15px;
  padding-right: 30px;
  padding-left: 30px;
  margin-top: 10px;
}

.user-profile-container {
  display: flex;
  flex-direction: column;
  width: 300px;
  padding-right: 15px;
  padding-left: 15px;
  padding-bottom: 15px;
}
