.screen-container {
  flex: 1;
  padding-right: 30px;
  padding-left: 30px;
  margin-top: 20px;
  font-family: 'Rubik';
  display: flex;
  flex-direction: row;
}

.main-pane {
  flex: 1;
}

.right-pane {
  width: 326px;
}

.right-pane-container {
  display: flex;
  flex-direction: column;
  background-color: #F4F4F4;
  width: 290px;
  padding: 16px;
  padding-right: 18px;
  padding-left: 18px;
  padding-bottom: 18px;
  border-radius: 10px;
}

.title {
  color: #3D3D3D;
  font-size: 15px;
  font-weight: 500;
}

.subtitle {
  font-size: 14px;
  color: #696969;
}

.flex-container-column {
  display: flex;
  flex-direction: column;
}

.flex-container-row {
  display: flex;
  flex-direction: row;
}

.flex-one {
  flex: 1;
}

.right-pane-close-curve-containers {
  width: 8px;
  background-color: #f4f4f4;
}

.right-pane-close-curve-left {
  height: 100%;
  width: 100%;
  background-color: white;
  border-bottom-right-radius: 6px;
}

.right-pane-close-curve-right {
  height: 100%;
  width: 100%;
  background-color: white;
  border-bottom-left-radius: 6px;
}

.right-pane-close-button-icon {
  font-size: 14px;
  margin-right: 5px;
}

.right-pane-close-button-text {
  font-size: 12px;
  color: #696969;
  font-weight: 500;
}

.right-pane-close-button-right-margin {
  width: 20px;
}

.right-pane-close-button {
  display: flex;
  flex-direction: row;
  align-items: center;
  background-color: #f4f4f4;
  padding: 10px;
  padding-bottom: 10px;
  padding-right: 15px;
  padding-left: 12px;
  border-top-right-radius: 10px;
  border-top-left-radius: 10px;
}

.right-pane-close-button:hover {
  cursor: pointer;
}
