.projects-fullscreen {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 100px;
}

.projects-center-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.update-profile-title {
  color: #3D3D3D;
  font-size: 15px;
  font-weight: bold;
  display: flex;
  align-items: center;
  margin-bottom: 8px;
}

.section-container {
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
}

.section-title {
  color: #3D3D3D;
  font-size: 15px;
  font-weight: bold;
  padding-left: 10px;
  display: flex;
  align-items: center;
}

.section-title-text {
  margin-top: 3px;
}

.item-container {
  display: flex;
  flex-direction: column;
  background-color: #F4F4F4;
  width: 290px;
  padding: 12px;
  padding-right: 18px;
  padding-left: 18px;
  border-radius: 10px;
  margin-top: 10px;
  margin-right: 10px;
}

.item-container:hover {
  cursor: pointer;
}

.item-title {
  color: #3D3D3D;
  font-size: 15px;
  font-weight: 500;
}

.item-key-value-container {
  display: flex;
  flex-direction: column;
  margin-top: 10px;
  color: #696969;
}

.item-key-value-row {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 5px;
  font-size: 14px;
}

.item-progress-bar-container {
  margin-top: 12px;
  margin-bottom: 5px;
}

.projects-list-container {
  display: flex;
  flex-direction: row;
  display: flex;
  flex-wrap: wrap;
}

.new-project-button {
  color: #59AFFF;
  font-size: 20px;
  margin-left: 8px;
}

.new-project-button:hover {
  color: #69b7ff;
  cursor: pointer;
}
