.internal-user-assignees-card {
  display: flex;
  flex-direction: column;
  background-color: #f4f4f4;
  border-radius: 10px;
  border-bottom-right-radius: 0px;
  flex: 1;
  overflow: hidden;
  padding-bottom: 15px;
}

.internal-user-container {
  width: 100%;
  display: flex;
  flex-direction: column;
  margin-right: 20px;
  margin-bottom: 20px;
  margin-top: 20px;
}

.internal-users-seperator-line {
  margin-top: 0px;
  margin-bottom: 0px;
}

.internal-users-empty-container {
  display: flex;
  height: 80px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-left: 15px;
  padding-right: 15px;
}

.internal-users-loading-text {
  font-size: 14px;
  margin-top: 115px;
  color: grey;
}

.internal-users-empty-text {
  text-align: center;
  font-size: 14px;
  color: grey;
}
