.checkbox-container {
  display: flex;
  align-items: center;
  height: 20px;
  width: 20px;
  border-radius: 5px;
  background-color: white;
}

.checkbox-container:hover {
  cursor: pointer;
}

.checkbox-container-selected {
  display: flex;
  align-items: center;
  height: 20px;
  width: 20px;
  border-radius: 5px;
  background-color: #59AFFF;
}

.checkbox-container-selected:hover {
  cursor: pointer;
}

.checkbox-container-locked {
  display: flex;
  align-items: center;
  height: 20px;
  width: 20px;
  border-radius: 5px;
  background-color: #cfcfcf;
}

.checkbox-container-locked-unchecked {
  display: flex;
  align-items: center;
  height: 20px;
  width: 20px;
  border-radius: 5px;
  background-color: white;
}

.checkbox-check-icon {
  color: white;
  font-size: 10px;
  margin-left: 5px;
}
