.team-card {
  display: flex;
  flex-direction: column;
  background-color: #f4f4f4;
  border-radius: 10px;
  flex: 1;
  overflow: visible;
}

.team-pane-cards-container {
  display: flex;
  margin-right: 25px;
  flex-direction: column;
}

.team-card-header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 15px;
}

.team-seperator-line {
  margin-top: 0;
  margin-bottom: 0;
}

.team-member-name {
  font-size: 11px;
  font-weight: 500;
  color: #696969;
  flex: 1;
}

.team-member-role {
  font-size: 11px;
  font-weight: 500;
  color: #696969;
  width: 160px;
}

.team-role-header {
  font-size: 11px;
  font-weight: 500;
  color: #696969;
  width: 100px;
  text-align: center;
}

.team-member-row-container {
  display: flex;
  flex-direction: column;
}

.team-member-row div {
  font-size: 13px;
  color: #696969;
}

.team-member-row {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding-top: 12px;
  padding-bottom: 12px;
  padding-left: 15px;
}

.team-member-row-name {
  flex: 1;
}

.team-member-row-type {
  width: 160px;
}

.team-member-row-role {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100px;
}

.team-pane-team-loading-container {
  display: flex;
  height: 100px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.team-pane-team-loading-text {
  font-size: 14px;
  margin-top: 70px;
  color: grey;
}

.types-loading-container {
  display: flex;
  height: 60px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-left: 15px;
  padding-right: 15px;
}

.types-loading-text {
  text-align: center;
  font-size: 14px;
  color: grey;
  margin-top: 80px;
}
