.custom-dropdown {
  width: 150px;
  position: relative;
}

.dropdown-btn {
  display: flex;
  align-items: center;
  font-size: 13px;
  padding: 8px;
  padding-left: 12px;
  border-radius: 6px;
  background-color: white;
  cursor: pointer;
}

.dropdown-btn-locked {
  font-size: 13px;
  padding: 8px;
  border-radius: 6px;
  background-color: #fafafa;
  border: 2px solid #e7e7e7;
}

.dropdown-list {
  width: 150px;
  position: absolute;
  top: 100%;
  left: 0;
  max-height: 200px;
  overflow-y: auto;
  border-radius: 0 0 6px 6px;
  background-color: #fff;
  z-index: 1;
  background-color: #fbfbfb;
}

.dropdown-item {
  color: #4c4c4c;
  padding: 8px;
  font-size: 13px;
  cursor: pointer;
}

.dropdown-item:hover {
  background-color: #f2f2f2;
}
