.header {
  font-family: 'Rubik';
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 30px;
  padding-top: 15px;
  padding-bottom: 15px;
  align-items: center;
}

.drublo-header-title {
  font-size: 30px;
  font-weight: bold;
  color: #404040;
}

.header-project-name {
  font-size: 15px;
  color: #3D3D3D;
}

.left-div, .right-div {
  display: flex;
  align-items: center;
  height: 100%;
}

.left-div {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}

.right-div-name {
  font-weight: 500;
}

.right-div {
  font-size: 14px;
  color: #696969;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: flex-end;
  padding: 10px;
}

.right-div:hover {
  cursor: pointer;
  color: #3d3d3d;
}
