.main-container-sections {
  display: flex;
  flex-direction: row;
}

.team-screen-container {
  flex: 1;
  padding-right: 30px;
  padding-left: 10px;
  margin-top: 20px;
  font-family: 'Rubik';
  display: flex;
  flex-direction: row;
}
