.settings-name-card {
  display: flex;
  width: 300px;
  flex-direction: column;
  background-color: #f4f4f4;
  border-radius: 10px;
  flex: 1;
  overflow: hidden;
  padding-bottom: 15px;
}

.settings-name-card-container {
  display: flex;
  flex-direction: column;
  margin-right: 20px;
  margin-bottom: 20px;
}

.settings-name-seperator-line {
  margin-top: 0;
  margin-bottom: 0;
}

.settings-name-card-header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 15px;
  padding-bottom: 12px;
}

.settings-name-save-button {
  width: 70px;
  margin-top: 10px;
  align-self: flex-end;
}

.settings-name-input-container {
  display: flex;
  flex-direction: column;
  padding-right: 15px;
  padding-left: 15px;
  margin-top: 15px;
}
