.team-invite-details-title {
  margin-bottom: 15px;
}

.team-invite-pending-title {

}

.team-invite-input {
  flex: 1;
}

.team-invite-input-row {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 5px;
}

.team-invite-input-key {
  font-size: 13px;
  width: 50px;
  color: #696969;
}

.invite-send-button {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #59AFFF;
  margin-left: 8px;
  height: 40px;
  width: 40px;
  border-radius: 10px;
  color: white;
  font-size: 16px;
}

.invite-send-button:hover {
  background: #69b7ff;
  cursor: pointer;
}

.team-invite-pending-emails {
  font-size: 14px;
  color: #696969;
}

.team-invite-pending-cancel {
  font-size: 14px;
  color: #59AFFF;
}

.team-invite-pending-cancel:hover {
  color: #69b7ff;
  cursor: pointer;
}

.team-invite-pending-row {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin-top: 15px;
}
