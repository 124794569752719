.new-phase-container {
  display: flex;
  flex-direction: column;
}

.new-phase-title {
  margin-bottom: 10px;
}

.new-phase-button {
  margin-top: 5px;
  font-size: 14px;
  height: 30px;
}

.new-phase-input {
  flex: 1;
}

.new-phase-input-row {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 10px;
}

.new-phase-input-key {
  font-size: 14px;
  width: 80px;
  color: #696969;
}
