.types-card {
  display: flex;
  width: 300px;
  flex-direction: column;
  background-color: #f4f4f4;
  border-radius: 10px;
  border-bottom-right-radius: 0px;
  flex: 1;
  overflow: hidden;
  padding-bottom: 15px;
}

.types-card-header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 15px;
  padding-bottom: 12px;
}

.types-seperator-line {
  margin-top: 0;
  margin-bottom: 0;
}

.type-pane-row {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding-right: 15px;
  padding-left: 15px;
  margin-top: 15px;
}

.type-input {
  flex: 1;
}

.type-lock-icon {
  color: #3d3d3d;
  font-size: 14px;
}

.type-trash-icon {
  color: #ff6666;
  font-size: 14px;
}

.type-trash-icon:hover {
  color: #ff7f7f;
  cursor: pointer;
}

.types-card-container {
  display: flex;
  flex-direction: column;
  margin-right: 20px;
  margin-bottom: 20px;
}

.add-type-container {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  color: #59AFFF;
}

.type-curve-container {
  background-color: #f4f4f4;
  width: 30px;
}

.type-task-button:hover {
  cursor: pointer;
  color: #69b7ff;
}

.type-cuver-white-container {
  height: 100%;
  width: 100%;
  background-color: white;
  border-top-right-radius: 10px;
}

.type-task-button {
  background-color: #f4f4f4;
  padding: 8px 15px;
  border-bottom-right-radius: 10px;
  border-bottom-left-radius: 10px;
}

.type-add-button {
  margin-right: 15px;
  height: 22px;
  margin-top: 15px;
  flex: 1;
}

.type-cancel-button {
  margin-left: 15px;
  margin-right: 5px;
  height: 22px;
  margin-top: 15px;
  width: 60px;
}

.type-input-container {
  display: flex;
  flex-direction: column;
  padding-right: 15px;
  padding-left: 15px;
  margin-top: 15px;
}
